import { notificationsApiClient } from "@api/client";
import { notificationsApiUrl } from "./notificationsApi";
import { OpenIdConfig } from "@models/openIdConfig";
import { AdminConfig } from "@frontend/configuration";

const getFileToken = (): Promise<string> => {
  return notificationsApiClient
    .request<string>({
      url: notificationsApiUrl() + "account/token",
    })
    .then((r: any) => r.data.token);
};

const getConfig = (token: string): Promise<AdminConfig> => {
  return fetch(
    "configuration/config.json?" +
      new URLSearchParams({
        token,
      }).toString(),
  ).then((resp) => {
    if (!resp.ok) {
      throw resp;
    }
    return resp.json();
  });
};

const getInitialOpenIdCreds = (): Promise<OpenIdConfig> => {
  return fetch("/init.json").then((resp) => {
    if (!resp.ok) {
      throw resp;
    }
    return resp.json();
  });
};

export const rootApi = {
  getFileToken,
  getConfig: getConfig,
  getInitialOpenIdCreds,
};

export type TRootApi = typeof rootApi;
