import { merge, mergeWith, uniq } from "lodash";
import {
  EMPTY,
  IncidentConfig,
  SET,
  TFlightStatusFilterValue,
} from "@models/incidentConfig";
import { PtsEventScheduleOptions } from "@models/pts";
import { extractLocalized } from "@i18n/utils";
import { getConfig } from "@di";

export const getSelectedTypes = (
  included: string[],
  excluded: string[],
): {
  filterType: "includes" | "excludes";
  selectedTypes: string[];
} => {
  if (included.length) {
    return {
      filterType: "includes",
      selectedTypes: [...included],
    };
  } else if (excluded.length) {
    return {
      filterType: "excludes",
      selectedTypes: [...excluded],
    };
  }

  return {
    filterType: "includes",
    selectedTypes: [],
  };
};

export const flightStatusFilterValuesDescription: Record<
  TFlightStatusFilterValue,
  {
    inboundFlightStatusValue: IncidentConfig["inboundFlightStatus"];
    outboundFlightStatusValue: IncidentConfig["outboundFlightStatus"];
  }
> = {
  TOW_OFF: { inboundFlightStatusValue: SET, outboundFlightStatusValue: EMPTY },
  TOW_ON: { inboundFlightStatusValue: EMPTY, outboundFlightStatusValue: SET },
  INBOUNDS_ONLY: {
    inboundFlightStatusValue: SET,
    outboundFlightStatusValue: null,
  },
  FULL_FLIGHTS_ONLY: {
    inboundFlightStatusValue: SET,
    outboundFlightStatusValue: SET,
  },
  TOW_ON_FULL_FLIGHTS_ONLY: {
    inboundFlightStatusValue: null,
    outboundFlightStatusValue: SET,
  },
};

export const getStatusValue = ({
  inboundFlightStatus,
  outboundFlightStatus,
}: Pick<IncidentConfig, "inboundFlightStatus" | "outboundFlightStatus">) => {
  const values = Object.keys(
    flightStatusFilterValuesDescription,
  ) as TFlightStatusFilterValue[];
  const value = values.find(
    (key) =>
      flightStatusFilterValuesDescription[key].inboundFlightStatusValue ===
        inboundFlightStatus &&
      flightStatusFilterValuesDescription[key].outboundFlightStatusValue ===
        outboundFlightStatus,
  );

  return value || "";
};

export const customMerge = (...args: Parameters<typeof merge>) => {
  // Replace array by target value instead of merge
  const customizer = (objValue: any, targetValue: any) => {
    if (Array.isArray(objValue)) {
      return targetValue;
    }
  };

  mergeWith(...args, customizer);
};

export function parseTurnParam(
  parameterName: PtsEventScheduleOptions["referencePoint"],
) {
  const config = getConfig();
  const paramInfo = config.incidentTurnaroundParams.find(
    (p) => p.id === parameterName,
  )?.label;
  return extractLocalized(paramInfo, parameterName);
}

export function getUniqPropValues<T extends Record<string, any>>(
  items: T[],
  query: Partial<T>,
  prop: keyof T,
): string[] {
  return uniq(
    items
      .filter((i) =>
        Object.entries(query).every(
          ([key, value]) => !value || i[key] == value,
        ),
      )
      .map((e) => e[prop] || ""),
  ).filter(Boolean);
}
