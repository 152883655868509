import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import "./style.scss";

type Props = {
  className?: string;
};

const Spinner: React.FC<Props> = ({ className }) => {
  return <div className={classNames("spinner", className)}></div>;
};

export default observer(Spinner);
