import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";

import DutyPhonesGroup from "./DutyPhonesGroup";
import DutyPhoneModal from "./DutyPhoneModal";
import Spinner from "@components/Common/Spinner";
import { HomeContext } from "@services/react";
import { exportDutyPhonesToCSV } from "@services/export";
import ExportButton from "@components/Common/ExportButton";
import Content from "@components/Common/Content";

import styles from "./style.module.scss";

const DutyPhonesPage = () => {
  const { notificationGroups: groups, dutyPhonesPageStore } =
    useContext(HomeContext);
  const {
    initDutyPhones,
    ready: isReady,
    dutyPhonesModalData: modalData,
  } = dutyPhonesPageStore;

  useEffect(() => {
    initDutyPhones();
  }, []);

  if (!isReady) {
    return <Spinner />;
  }

  return (
    <Content>
      <div className={styles.dutyPhonesPage}>
        <div className={styles.menu}>
          <ExportButton
            onClick={() =>
              exportDutyPhonesToCSV(dutyPhonesPageStore.dutyPhones)
            }
          />
        </div>
        {groups.map((g) => (
          <DutyPhonesGroup key={g.id} group={g.id} />
        ))}
        {modalData && <DutyPhoneModal initialValue={modalData} />}
      </div>
    </Content>
  );
};

export default observer(DutyPhonesPage);
