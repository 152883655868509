import React, { PropsWithChildren } from "react";
import { observer } from "mobx-react";
import { ModalCell, ModalSeparator, Radio } from "@frontend/assaia-ui";
import { useIntl } from "react-intl";

import { MergedTurnaroundAttributeType } from "@models/incidentConfig";
import { getConfig } from "@di";
import { useExtractLocalized } from "@i18n/hooks";

type Props = PropsWithChildren<{
  title: string;
  triggerType: MergedTurnaroundAttributeType;
  onTrigger: (v: MergedTurnaroundAttributeType) => void;
}>;

const IncidentTriggerType = ({
  title,
  triggerType,
  onTrigger,
  children,
}: Props) => {
  const { texts } = getConfig();
  const extractLocalized = useExtractLocalized();
  const intl = useIntl();

  return (
    <>
      <ModalSeparator title={title} />
      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Event",
          description: "IncidentTriggerType event title",
        })}
        subtitle={intl.formatMessage({
          defaultMessage: "Actions during the turnaround",
          description: "IncidentTriggerType event title",
        })}
        onClick={() => onTrigger("event")}
        leftIcon={<Radio active={triggerType === "event"} />}
      />
      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Time parameter",
          description: "IncidentTriggerType event title",
        })}
        subtitle={extractLocalized(texts.flight_parameter_subtitle)}
        onClick={() => onTrigger("turnaround_param")}
        leftIcon={<Radio active={triggerType === "turnaround_param"} />}
      />
      {children}
    </>
  );
};

export default observer(IncidentTriggerType);
