import { makeAutoObservable } from "mobx";
import AuthStore from "../AuthStore";
import { HomeStore } from "../HomeStore";

//TODO root store seems useless
export class RootStore {
  authStore: AuthStore;
  homeStore: HomeStore | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.authStore = new AuthStore(this);
  }

  initHomeStore(): HomeStore {
    const homeStore = new HomeStore(this);
    this.setHomeStore(homeStore);

    return homeStore;
  }

  disposeHomeStore() {
    this.setHomeStore(null);
  }

  setHomeStore(homeStore: RootStore["homeStore"]) {
    this.homeStore = homeStore;
  }
}

export const rootStore = new RootStore();

// @ts-ignore
window._rootStore = rootStore;
