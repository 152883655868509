// Creates an FNV hash key based on string value
export const fnv1a = (value: string) => {
  if (typeof value !== "string") {
    throw new TypeError("Input value must be a string.");
  }

  const FNV_prime = 0x01000193; // 16777619
  let hash = 0x811c9dc5; // 2166136261 (FNV offset basis)

  for (let i = 0; i < value.length; i++) {
    hash ^= value.charCodeAt(i);
    hash *= FNV_prime;
    hash >>>= 0; // Обеспечивает, что hash остается 32-битным беззнаковым целым
  }

  let hashString = hash.toString(16);

  if (hashString.length < 8) {
    hashString = "0".repeat(8 - hashString.length) + hashString;
  }

  return hashString;
};
