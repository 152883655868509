import React, { PropsWithChildren } from "react";
import { observer } from "mobx-react";

import s from "./style.module.scss";

const Content = ({ children }: PropsWithChildren<{}>) => {
  return <div className={s.content}>{children}</div>;
};

export default observer(Content);
