import {
  Icons24Trash,
  Icons24AddUser,
  SecondaryButton,
} from "@frontend/assaia-ui";
import { FormattedMessage } from "react-intl";
import { observer } from "mobx-react";
import { useContext } from "react";

import { HomeContext } from "@services/react";
import ExportButton from "@components/Common/ExportButton";
import { getConfig } from "@di";
import styles from "./style.module.scss";

const UsersMenu = () => {
  const { enableOTPTokenReset } = getConfig();
  const { usersPageStore } = useContext(HomeContext);
  const {
    toggleUsersActive,
    deleteUsers,
    setSelectedUsers,
    resetPassword,
    resetOTPToken,
    exportUsers,
    createUser,
    usersMap,
    exportingUsers,
    selectedUsersIds: selectedIds,
  } = usersPageStore;

  const onToggleActive = (val: boolean) => {
    const ids = selectedIds.filter((id) => usersMap[id]?.active !== val);
    toggleUsersActive(ids, val);
  };

  const canActivate = selectedIds.some((id) => !usersMap[id]?.active);
  const canDeactivate = selectedIds.some((id) => usersMap[id]?.active);

  return (
    <div className={styles.usersMenu}>
      {!!selectedIds.length && (
        <>
          <SecondaryButton
            colorTheme="dark"
            onClick={() => setSelectedUsers([])}
          >
            <FormattedMessage
              description="Users menu"
              defaultMessage="Clear selection"
            />
          </SecondaryButton>
          {canActivate && (
            <SecondaryButton
              colorTheme="dark"
              onClick={() => onToggleActive(true)}
            >
              <FormattedMessage
                defaultMessage="Activate"
                description="Users menu"
              />
            </SecondaryButton>
          )}
          {canDeactivate && (
            <SecondaryButton
              colorTheme="dark"
              onClick={() => onToggleActive(false)}
            >
              <FormattedMessage
                defaultMessage="Deactivate"
                description="Users menu"
              />
            </SecondaryButton>
          )}
          <SecondaryButton
            colorTheme="dark"
            onClick={() => resetPassword(selectedIds)}
          >
            <FormattedMessage
              description="Users menu"
              defaultMessage="Reset password"
            />
          </SecondaryButton>
          {enableOTPTokenReset && (
            <SecondaryButton
              colorTheme="dark"
              onClick={() => resetOTPToken(selectedIds)}
            >
              <FormattedMessage
                defaultMessage="Reset OTP token"
                description="Users menu"
              />
            </SecondaryButton>
          )}
          <SecondaryButton
            colorTheme="dark"
            onClick={() => deleteUsers(selectedIds)}
            className={styles.red}
            leftIcon={<Icons24Trash />}
          >
            <FormattedMessage
              defaultMessage="Delete"
              description="Users menu"
            />
          </SecondaryButton>
        </>
      )}
      {!selectedIds.length && (
        <SecondaryButton
          colorTheme="dark"
          onClick={createUser}
          leftIcon={<Icons24AddUser />}
        >
          <FormattedMessage
            defaultMessage="Add user"
            description="Users menu add user button"
          />
        </SecondaryButton>
      )}
      <ExportButton onClick={exportUsers} exporting={exportingUsers} />
    </div>
  );
};

export default observer(UsersMenu);
