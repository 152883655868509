import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";

import Spinner from "@components/Common/Spinner";
import { HomeContext } from "@services/react";
import PtsGroup from "./PtsGroup";
import Content from "@components/Common/Content";

import styles from "./style.module.scss";
import PtsMainModal from "@components/PtsPage/PtsMainModal";

const PtsListPage = () => {
  const { ptsPageStore, ptsGroups } = useContext(HomeContext);
  const { initPtsList, ready, ptsData } = ptsPageStore;

  useEffect(() => {
    initPtsList();
  }, []);

  if (!ready) {
    return <Spinner />;
  }

  return (
    <Content>
      <div className={styles.ptsPage}>
        {ptsGroups.map((group) => (
          <PtsGroup group={group} key={group.id} />
        ))}
      </div>
      {ptsData && <PtsMainModal ptsData={ptsData} />}
    </Content>
  );
};

export default observer(PtsListPage);
