import React from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router-dom";

import { URLS } from "@services/router";
import PtsListPage from "@components/PtsPage/PtsListPage";
import PtsDetailsPage from "@components/PtsPage/PtsDetailsPage";

const PtsPage = () => {
  return (
    <Switch>
      <Route path={URLS.PTS} component={PtsListPage} exact />
      <Route path={`${URLS.PTS}/:ptsId`} component={PtsDetailsPage} exact />
    </Switch>
  );
};

export default observer(PtsPage);
