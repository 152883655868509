import { createBrowserHistory } from "history";

export const browserHistory = createBrowserHistory();

export const URLS = {
  USERS: "/users",
  NOTIFICATIONS: "/notifications",
  DUTY_PHONES: "/duty-phones",
  PTS: "/pts",
} as const;
