import React, { useContext, useMemo } from "react";
import { observer } from "mobx-react";

import { HomeContext, IncidentModalStoreContext } from "@services/react";
import IncidentMainScreen from "./IncidentMainScreen";
import IncidentAttributeScreen from "./IncidentAttributeScreen";
import IncidentTimingScreen from "./IncidentTimingScreen";
import IncidentTimingScreenSettings from "./IncidentTimingScreenSettings";
import ModalFilterScreen from "@components/Common/ModalFilterScreen";
import IncidentModalStore from "@stores/IncidentModalStore";
import { Modal } from "@frontend/assaia-ui";

const IncidentModal = () => {
  const home = useContext(HomeContext);
  const {
    notificationsPageStore: { notificationModalData, newAlertGroup },
  } = home;

  const store = useMemo(
    () =>
      new IncidentModalStore(home, {
        existingIncidentConfig: notificationModalData,
        newAlertGroup,
      }),
    [],
  );

  const { modalScreen, setScreen, closeModal, filtersStore } = store;

  return (
    <IncidentModalStoreContext.Provider value={store}>
      <Modal>
        {modalScreen === "main" && <IncidentMainScreen />}
        {modalScreen === "attribute" && <IncidentAttributeScreen />}
        {modalScreen === "timing" && <IncidentTimingScreen />}
        {modalScreen === "timingOptions" && <IncidentTimingScreenSettings />}
        {modalScreen === "filters" && (
          <ModalFilterScreen
            onBack={() => setScreen("main")}
            onClose={closeModal}
            filtersStore={filtersStore}
          />
        )}
      </Modal>
    </IncidentModalStoreContext.Provider>
  );
};

export default observer(IncidentModal);
