import { observer } from "mobx-react";
import { PrimaryButton, Modal, ModalHeader } from "@frontend/assaia-ui";
import { FormattedMessage } from "react-intl";
import React from "react";

import s from "./style.module.scss";

type Props = {
  text: string;
  onNo: () => void;
  onYes: () => void;
};
const YesNoModal = ({ text, onNo, onYes }: Props) => {
  return (
    <Modal
      className={s.yesNoModal}
      title={text}
      onClose={onNo}
      containerClassName={s.backdrop}
    >
      <ModalHeader title={text} onClose={onNo} />
      <div className={s.buttons}>
        <PrimaryButton onClick={onNo} className={s.danger}>
          <FormattedMessage defaultMessage="No" description="yes/no modal" />
        </PrimaryButton>
        <PrimaryButton onClick={onYes}>
          <FormattedMessage defaultMessage="Yes" description="yes/no modal" />
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default observer(YesNoModal);
