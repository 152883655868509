import { cloneDeep } from "lodash";
import { makeAutoObservable } from "mobx";
import { DutyPhone, NewDutyPhone } from "@models/dutyPhone";
import { confirmModal } from "@services/syncedModal";
import { DutyPhonesPageStore } from "@stores/DutyPhonesStore";

export class DutyPhoneModalStore {
  data: DutyPhone | NewDutyPhone;

  constructor(
    readonly dutyPhonesStore: DutyPhonesPageStore,
    v: DutyPhoneModalStore["data"],
  ) {
    this.data = cloneDeep(v);

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get isNewDutyPhone() {
    return !("id" in this.data);
  }

  get isNumberValid() {
    const { data } = this;
    return !(data.phone.includes("_") || data.phone.length <= 0);
  }

  close = () => {
    this.dutyPhonesStore.editDutyPhone();
  };

  toggleActive(active: boolean) {
    this.data.active = active;
  }

  onPhoneChanged = (phone: string) => {
    this.data.phone = phone;
  };

  onDescriptionChanged = (description: string) => {
    this.data.description = description;
  };

  submit = () => {
    this.dutyPhonesStore.saveDutyPhone(this.data);
    this.close();
  };

  deleteDutyPhone = async () => {
    const { data } = this;
    if (!("id" in data)) {
      return;
    }

    const confirmed = await confirmModal();
    if (!confirmed) {
      return;
    }

    this.dutyPhonesStore.deleteDutyPhones([data.id]);
    this.close();
  };
}
