import { action, makeAutoObservable, runInAction } from "mobx";
import Role from "@models/role";
import { cloneDeep, keyBy, sortBy } from "lodash";
import { customMerge } from "@services/data/common";
import { computedFn } from "mobx-utils";
import {
  NewIncidentConfig,
  UnifiedIncidentConfig,
} from "@models/incidentConfig";
import { defineMessage, IntlShape } from "react-intl";

import { createLogger } from "@services/logger";
import { TNotificationsApi } from "@api/notificationsApi";
import { HomeStore } from "../HomeStore";
import {
  getTimerLabelData,
  getTriggerLabelData,
} from "@services/data/incidents";
import { TResourceValue } from "@models/common";

const logger = createLogger("[NotificationsStore]");

export class NotificationsPageStore {
  notifications: UnifiedIncidentConfig[] = [];
  showNotificationModal = false;
  notificationModalData: UnifiedIncidentConfig | null = null;
  newAlertGroup: TResourceValue | null = null;
  ready = false;
  groups: Role[] = [];
  searchQuery = "";
  stands: string[] = [];
  highlightItemId: string | null = null;

  constructor(
    private home: HomeStore,
    private api: TNotificationsApi,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get notificationGroups() {
    return this.home.notificationGroups;
  }

  get aircrafts() {
    return this.home.aircrafts;
  }

  get notificationsTable(): Record<string, UnifiedIncidentConfig | undefined> {
    return keyBy(this.notifications, "id");
  }

  init() {
    const handleError = (error: Error) => {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: something went wrong",
          description: "Error message when notifications could not be loaded",
        }),
        type: "error",
      });
      throw error;
    };

    const promises = [
      this.api
        .getStands()
        .then(action((items) => (this.stands = items)))
        .catch(handleError),
      this.home.initAircrafts().catch(handleError),
      this.home.initAirlines().catch(handleError),
      this.api
        .getNotifications()
        .then(action((items) => (this.notifications = items.unifiedConfigs)))
        .catch(handleError),
    ];

    return Promise.all(promises).then(
      action(() => {
        this.ready = true;
      }),
    );
  }

  // TODO dont use intl directly in this function
  //
  /**
   * Memoized
   */
  prepareNotificationsByGroup = computedFn(
    (group: TResourceValue, intl: IntlShape) => {
      const query = this.searchQuery.toLowerCase().trim();
      const allNotifications = this.notifications;
      let items = sortBy(
        allNotifications.filter((n) => n.group.id === group.id),
        "data.objectName",
      );
      if (query.length >= 2) {
        items = items.filter((i) => {
          const triggerLabel = getTriggerLabelData(i, intl);
          const startRangeBoundary = getTimerLabelData(
            i.data.startRangeBoundary,
          );
          const firingPointInTime = getTimerLabelData(i.data.firingPointInTime);
          const endRangeBoundary = getTimerLabelData(i.data.endRangeBoundary);

          const stringsToCheck = [
            ...triggerLabel.label,
            ...Object.values(triggerLabel).flat(),
            ...Object.values(startRangeBoundary).flat(),
            ...Object.values(firingPointInTime).flat(),
            ...Object.values(endRangeBoundary).flat(),
          ].map((s) => s.toLowerCase());

          const text = (i.customText || "").toLowerCase();
          return (
            stringsToCheck.some((s) => s.includes(query)) ||
            text.includes(query)
          );
        });
      }
      return items;
    },
  );

  editNotification(
    notification?: NotificationsPageStore["notificationModalData"],
  ) {
    this.notificationModalData = notification || null;
    this.showNotificationModal = !!notification;
  }

  /**
   * Opens modal
   */
  createNotification(group: TResourceValue) {
    this.newAlertGroup = group;
    this.showNotificationModal = true;
  }

  saveNotification(data: NewIncidentConfig, targetId?: string) {
    logger.log("saveNotification", cloneDeep(data), targetId);

    const promise = targetId
      ? this.api.patchNotification(targetId, data)
      : this.api.saveNewNotification(data);

    return promise
      .then((item) => {
        this._updateNotification(item as UnifiedIncidentConfig);

        this.home.setUIAlert({
          message: defineMessage({
            defaultMessage: "The changes have been applied",
            description: "Success message when notification has been saved",
          }),
          type: "info",
        });
        this.setHighlightItem(item.id);

        return item;
      })
      .catch((er) => {
        this.home.setUIAlert({
          message: defineMessage({
            defaultMessage: "Error: changes have not been applied",
            description: "Error message when notification has not been saved",
          }),
          type: "error",
        });
        throw er;
      });
  }

  async deleteNotifications(ids: string[]) {
    try {
      await this.api.deleteNotifications(ids);

      runInAction(() => {
        this.notifications = this.notifications.filter(
          (v) => !ids.includes(v.id),
        );
      });

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The changes have been applied",
          description: "Success message when notifications have been deleted",
        }),
        type: "info",
      });
    } catch (e) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when notifications have not been deleted",
        }),
        type: "error",
      });
      throw e;
    }
  }

  async toggleNotificationsActive(ids: string[], active: boolean) {
    try {
      await this.api.toggleNotificationsActive(ids, active);

      runInAction(() => {
        this.notifications
          .filter(({ id }) => ids.includes(id))
          .forEach((n) => (n.active = active));
      });

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The changes have been applied",
          description: "Success message when notifications have been toggled",
        }),
        type: "info",
      });
    } catch (e) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when notifications have not been toggled",
        }),
        type: "error",
      });
      throw e;
    }
  }

  setNotificationsQuery(query: string) {
    this.searchQuery = query;
  }

  setHighlightItem(id: NotificationsPageStore["highlightItemId"]) {
    this.highlightItemId = id;
  }

  private _updateNotification(data: UnifiedIncidentConfig) {
    const found = this.notificationsTable[data.id];
    if (!found) {
      this.notifications.push(data);
      return;
    }

    customMerge(found, data);
  }
}
