import { observer } from "mobx-react";

import { PtsEventScheduleOptions } from "@models/pts";
import PtsOperationChart from "../PtsOperationChart";
import TimeAxis from "../Timeaxis";
import { getPtsOperationChartPoints } from "@services/ptsOperationChart/ptsOperationChart";
import { getShortReferencePointTitle } from "@services/ptsUtils";

import styles from "./style.module.scss";

type Props = {
  startData: PtsEventScheduleOptions;
  endData: PtsEventScheduleOptions;
};

const PtsPreview: React.FC<Props> = ({ startData, endData }) => {
  const startResult = getPtsOperationChartPoints(startData);
  const endResult = getPtsOperationChartPoints(endData);

  const startResultAbs = startResult.absValues;
  const endResultAbs = endResult.absValues;

  const getBounds = (v: Record<any, any>) => {
    const vals = Object.values(v);
    const b1 = Math.min(...vals) * 60 * 1000;
    const b2 = Math.max(...vals) * 60 * 1000;

    const bounds: [number, number] =
      b1 !== b2 ? [b1, b2] : [-100 * 60 * 1000, 100 * 60 * 1000];

    return bounds;
  };

  const startBounds = getBounds(startResultAbs);
  const endBounds = getBounds(endResultAbs);

  const startRefference = getShortReferencePointTitle(startData.referencePoint);
  const endRefference = getShortReferencePointTitle(endData.referencePoint);

  return (
    <div className={styles.previewContainer}>
      <div className={styles.axisItem}>
        <TimeAxis bounds={startBounds} referenceValue={startRefference} />
      </div>
      <PtsOperationChart
        data={startData}
        calculatedPoints={startResult}
        className={styles.previewItem}
        isStart={true}
      />
      <div className={styles.axisItem}>
        <TimeAxis bounds={endBounds} referenceValue={endRefference} />
      </div>
      <PtsOperationChart
        data={endData}
        calculatedPoints={endResult}
        className={styles.previewItem}
        isStart={false}
      />
    </div>
  );
};

export default observer(PtsPreview);
