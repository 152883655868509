import { PtsEventScheduleOptions } from "@models/pts";

const PADDING = 0.16;

export const getPtsOperationChartPoints = (
  data: PtsEventScheduleOptions,
  referenceValue = 0
) => {
  const idealTime = data.idealTime;

  let orangeStart = data.orangeInterval.start
    ? Math.min(data.orangeInterval.start, idealTime)
    : null;
  let redStart = data.redInterval.start
    ? Math.min(data.redInterval.start, idealTime)
    : null;

  let orangeEnd = data.orangeInterval.end
    ? Math.max(data.orangeInterval.end, idealTime)
    : null;
  let redEnd = data.redInterval.end
    ? Math.max(data.redInterval.end, idealTime)
    : null;

  const nonNullsValues = [
    orangeStart,
    redStart,
    orangeEnd,
    redEnd,
    idealTime,
    referenceValue,
  ].filter((v) => v !== null) as number[];
  const min = Math.min(...nonNullsValues);
  const max = Math.max(...nonNullsValues);

  const diffMinMax = Math.abs(max - min);
  const leftPadding = min - diffMinMax * PADDING;
  const rightPadding = max + diffMinMax * PADDING;

  orangeStart = orangeStart ? orangeStart : min;
  redStart = redStart ? redStart : min;

  orangeEnd = orangeEnd ? orangeEnd : max;
  redEnd = redEnd ? redEnd : max;

  const result = {
    leftPadding,
    idealTime,
    orangeStart,
    redStart,
    orangeEnd,
    redEnd,
    referenceValue,
    rightPadding,
  };

  const absValues = { ...result };
  const vals = Object.values(result);

  const newMin = Math.min(...vals);
  const newMax = Math.max(...vals);
  const newDiff = Math.abs(newMax - newMin);

  Object.entries(absValues).forEach(([k, v]) => {
    (result as any)[k] = Math.abs(v - newMin) / newDiff;
  });

  return { values: result, absValues };
};
