import React from "react";
import { observer } from "mobx-react";
import {
  Icons24Export,
  SecondaryButton,
  IconLoader,
} from "@frontend/assaia-ui";

import { FormattedMessage } from "react-intl";
import styles from "./style.module.scss";

interface Props {
  onClick: () => void;
  exporting?: boolean;
}

const ExportButton: React.FC<Props> = ({ onClick, exporting = false }) => {
  return (
    <div className={styles.exportBtnContainer}>
      <SecondaryButton
        onClick={onClick}
        leftIcon={exporting ? <IconLoader /> : <Icons24Export />}
        colorTheme="dark"
        noBackground
        disabled={exporting}
      >
        <FormattedMessage
          defaultMessage="Export"
          description="Export button text"
        />
      </SecondaryButton>
    </div>
  );
};

export default observer(ExportButton);
