import { createContext } from "react";
import { RootStore } from "@stores/RootStore";
import { HomeStore } from "@stores/HomeStore";
import { PtsOperationModalStore } from "@stores/PtsOperationModalStore";
import IncidentModalStore from "@stores/IncidentModalStore";

export const RootContext = createContext<RootStore>({} as RootStore);

export const HomeContext = createContext<HomeStore>({} as HomeStore);

export const PtsOperationModalStoreContext =
  createContext<PtsOperationModalStore>({} as PtsOperationModalStore);

export const IncidentModalStoreContext = createContext<IncidentModalStore>(
  {} as IncidentModalStore,
);
