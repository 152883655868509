import { IncidentConfig } from "@models/incidentConfig";
import { camelCase, snakeCase } from "lodash";
import { formatNotificationGroupLabel } from "@services/data/incidents";
import { TResourceValue } from "@models/common";

export function camelCaseKeys(obj: any): any {
  return Object.keys(obj).reduce(
    (ccObj, field) => ({
      ...ccObj,
      [camelCase(field)]:
        obj[field] &&
        !Array.isArray(obj[field]) &&
        typeof obj[field] === "object"
          ? camelCaseKeys(obj[field])
          : obj[field],
    }),
    {},
  );
}

export function snakeCaseKeys(obj: any): any {
  return Object.keys(obj).reduce(
    (scObj, field) => ({
      ...scObj,
      [snakeCase(field)]:
        obj[field] &&
        !Array.isArray(obj[field]) &&
        typeof obj[field] === "object"
          ? snakeCaseKeys(obj[field])
          : obj[field],
    }),
    {},
  );
}

export function parseIncidentConfig({
  required_airlines,
  excluded_airlines,
  ...data
}: any): IncidentConfig {
  const config = camelCaseKeys(data) as IncidentConfig;

  const group: TResourceValue = {
    id: data.group,
    roleName: formatNotificationGroupLabel(data.group),
  };
  config.group = group;

  config.data.type = data.data.strategy;
  config.data.incidentType = data.incident_type;

  if (!config.requiredAircraftTypes) {
    config.requiredAircraftTypes = [];
  }
  if (!config.excludedAircraftTypes) {
    config.excludedAircraftTypes = [];
  }
  if (!config.requiredStands) {
    config.requiredStands = [];
  }
  if (!config.excludedStands) {
    config.excludedStands = [];
  }

  config.requiredAirlineIcaoAndIata = required_airlines || [];
  config.excludedAirlineIcaoAndIata = excluded_airlines || [];

  if ("detectedAttributes" in config.data) {
    config.data.detectedAttributes = config.data.detectedAttributes.map((v) =>
      camelCaseKeys(v),
    );
  }

  return config;
}

export const parseAircraftGroups = (data: any) =>
  data
    .filter((v: any) => v.group_types && v.group_types.length)
    .map(camelCaseKeys);
