import ReactDOM from "react-dom";
import YesNoModal from "../components/Common/YesNoModal";
import { getConfig } from "@di";
import { IntlProvider } from "@components/App/IntlProvider";
import { defineMessage, IntlContext, MessageDescriptor } from "react-intl";

const defaultMessage = defineMessage({
  defaultMessage: "Are you sure?",
  description: "Default message for confirm modal",
});

// TODO make it inside react app, not as separate react app
export async function confirmModal(
  text: MessageDescriptor = defaultMessage,
  i18nValues?: any,
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const container = document.getElementById("confirm-modal-container");
    if (!container) {
      reject(new Error("Container for confirm modal is not found!"));
      return;
    }

    const onNo = () => {
      ReactDOM.unmountComponentAtNode(container);
      resolve(false);
    };

    const onYes = () => {
      ReactDOM.unmountComponentAtNode(container);
      resolve(true);
    };

    const { locales, defaultLocale } = getConfig();

    ReactDOM.render(
      <IntlProvider value={{ locales, defaultLocale }}>
        <IntlContext.Consumer>
          {(intl) => (
            <YesNoModal
              text={intl.formatMessage(text, i18nValues)}
              onNo={onNo}
              onYes={onYes}
            />
          )}
        </IntlContext.Consumer>
      </IntlProvider>,
      container,
    );
  });
}
