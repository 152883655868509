import React, { useContext } from "react";
import styles from "./style.module.scss";
import { observer } from "mobx-react";
import classNames from "classnames";
import { capitalize } from "lodash";
import { defineMessage, IntlShape, useIntl } from "react-intl";

import { parseTurnParam } from "@services/data/common";
import { IncidentModalStoreContext } from "@services/react";
import { MergedTurnaroundAttribute, TriggerKeys } from "@models/incidentConfig";
import {
  notificationTriggerLabels,
  TURN_LENGTH_LABELS,
  FLIGHT_STATUS_LABELS,
} from "@i18n/messages";
import { formatEventName, formatObjectName } from "@services/data/incidents";

interface Props {
  data: Parameters<typeof getAttributeShortInfo>[0][];
  error?: boolean;
}

const NOT_SPECIFIED = defineMessage({
  defaultMessage: "Not specified",
  description: "Attr caption info",
});

// TODO i18n
const getAttributeShortInfo = (
  props: {
    attribute: MergedTurnaroundAttribute;
    parseTimeShift?: boolean;
    trigger?: TriggerKeys;
    required?: boolean;
  },
  intl: IntlShape,
) => {
  const { attribute, parseTimeShift = true, trigger, required = true } = props;
  const notSpecified = required ? intl.formatMessage(NOT_SPECIFIED) : "";
  let result = "";
  if (attribute.type === "event") {
    const object = attribute.objectName
      ? formatObjectName(attribute.objectName)
      : null;
    const event =
      attribute.objectName && attribute.eventName
        ? formatEventName(attribute.objectName, attribute.eventName)
        : null;
    if (!object || !event) {
      return notSpecified;
    }
    result += `${object} ${event}`;
  } else {
    const parameter = parseTurnParam(attribute.parameterName);
    result += parameter;
  }

  if (trigger) {
    result += `, ${capitalize(intl.formatMessage(notificationTriggerLabels[trigger]))}`;
  }

  if (parseTimeShift) {
    const parsedTimeShift = Math.round(attribute.timeShift / 60);
    const timeShift =
      parsedTimeShift >= 0 ? `+${parsedTimeShift}m` : `${parsedTimeShift}m`;
    result += `, ${timeShift}`;
  }

  return result;
};

export const IncidentAttributeCaption: React.FC<Props> = observer(
  ({ data, error = false }) => {
    const intl = useIntl();
    const result = data
      .map((v) => getAttributeShortInfo(v, intl))
      .filter(Boolean);

    return (
      <div className={classNames(styles.caption, { [styles.error]: error })}>
        {result.join(" – ")}
      </div>
    );
  },
);

export const IncidentFilterCaption: React.FC<{}> = observer(({}) => {
  const {
    selectedStands,
    selectedAircraftTypes,
    aircraftFilterType,
    turnaroundLength,
    flightStatus,
  } = useContext(IncidentModalStoreContext).filtersStore;
  const intl = useIntl();

  const result: string[] = [];
  if (flightStatus) {
    result.push(intl.formatMessage(FLIGHT_STATUS_LABELS[flightStatus]));
  }
  if (turnaroundLength) {
    result.push(
      capitalize(intl.formatMessage(TURN_LENGTH_LABELS[turnaroundLength])),
    );
  }
  if (selectedAircraftTypes.length) {
    result.push(
      aircraftFilterType === "includes"
        ? intl.formatMessage(
            {
              defaultMessage: "Aircrafts included: {count}",
              description: "IncidentFilterCaption",
            },
            { count: selectedAircraftTypes.length },
          )
        : intl.formatMessage(
            {
              defaultMessage: "Aircrafts excluded: {count}",
              description: "IncidentFilterCaption",
            },
            { count: selectedAircraftTypes.length },
          ),
    );
  }
  if (selectedStands.length) {
    result.push(
      aircraftFilterType === "includes"
        ? intl.formatMessage(
            {
              defaultMessage: "Stands included: {count}",
              description: "IncidentFilterCaption",
            },
            { count: selectedStands.length },
          )
        : intl.formatMessage(
            {
              defaultMessage: "Stands excluded: {count}",
              description: "IncidentFilterCaption",
            },
            { count: selectedStands.length },
          ),
    );
  }

  return (
    <div className={styles.caption}>
      {result.length
        ? result.join(" – ")
        : intl.formatMessage({
            defaultMessage: "All turnarounds",
            description: "IncidentFilterCaption",
          })}
    </div>
  );
});
