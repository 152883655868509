// TODO not good that regular we have label as formattedMessage and items that are not localized
// TODO dont use intl directly in this function
import {
  TurnaroundPointInTime,
  UnifiedIncidentConfig,
} from "@models/incidentConfig";
import { IntlShape } from "react-intl";
import { notificationTriggerLabels } from "@i18n/messages";
import { parseTurnParam } from "@services/data/common";
import { getConfig } from "@di";
import { extractLocalized } from "@i18n";

export const formatObjectName = (objectName: string) => {
  const config = getConfig();
  const eventInfo = config.turnaroundEvents.find(
    (e) => e.objectName === objectName,
  );
  return extractLocalized(eventInfo?.objectLabel, objectName);
};
export const formatObjectPosition = (position: string) => {
  const config = getConfig();
  const eventInfo = config.turnaroundEvents.find(
    (e) => e.objectPosition === position,
  );
  return extractLocalized(eventInfo?.objectPositionLabel, position);
};
export const formatObjectType = (type: string) => {
  const config = getConfig();
  const eventInfo = config.turnaroundEvents.find((e) => e.objectType === type);
  return extractLocalized(eventInfo?.objectTypeLabel, type);
};
export const formatEventName = (objectName: string, eventName: string) => {
  const config = getConfig();
  const eventInfo = config.turnaroundEvents.find(
    (e) => e.objectName === objectName && e.eventType === eventName,
  );
  return `${extractLocalized(eventInfo?.objectLabel, objectName)} ${extractLocalized(eventInfo?.eventLabel, eventName).toLowerCase()}`;
};
export const formatNotificationGroupLabel = (group: string) =>
  group.split("::")[0] || group;
export const getTriggerLabelData = (
  n: UnifiedIncidentConfig,
  intl: IntlShape,
) => {
  const trigger = n.data.detectedAttributes[0];
  return {
    label:
      trigger.type === "event"
        ? formatEventName(trigger.objectName, trigger.eventName)
        : parseTurnParam(trigger.parameterName),
    items: [intl.formatMessage(notificationTriggerLabels[n.data.trigger])],
  };
};

export const getTimerLabelData = (n: TurnaroundPointInTime | null) => {
  if (!n) {
    return {
      label: "",
      items: [],
    };
  }

  const { timeShift, turnaroundAttribute } = n;
  return {
    label:
      turnaroundAttribute.type === "event"
        ? formatEventName(
            turnaroundAttribute.objectName,
            turnaroundAttribute.eventName,
          )
        : parseTurnParam(turnaroundAttribute.parameterName),
    items: [`${Math.round(timeShift / 60)}m`],
  };
};
