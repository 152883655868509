import React, {
  createContext,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import { asValue } from "awilix";
import { IntlProvider as BaseIntlProvider } from "react-intl";
import { captureException } from "@sentry/browser";

import di from "@di";
import {
  DEFAULT_LOCALE,
  loadLocaleData,
  getUserLocale,
  isLocaleSupported,
  Locale,
  LocaleData,
  saveUserLanguageCode,
  handleIntlError,
  handleIntlWarning,
  AVAILABLE_LOCALES,
  Locales,
} from "@i18n";
import { isDev } from "@services/constants";

export const IntlContext = createContext<{
  locales: Locales;
  locale: Locale;
  switchLocale: (locale: Locale) => void | Promise<void>;
}>({
  switchLocale: () => {
    if (isDev) {
      throw new Error("[IntlContext] `switchLocale` is not implemented!");
    }
  },
  locales: AVAILABLE_LOCALES,
  locale: DEFAULT_LOCALE,
});

export const IntlProvider = ({
  children,
  value: { locales, defaultLocale },
}: {
  children: ReactNode;
  value: { locales: Locales; defaultLocale: Locale };
}) => {
  const [{ locale, messages }, setState] = useState<{
    locale: null | Locale;
    messages: LocaleData;
  }>({
    locale: null,
    messages: {},
  });

  const switchLocale = useCallback(
    async (nextLocale: Locale) => {
      if (locale === nextLocale) {
        return;
      }

      if (!isLocaleSupported(nextLocale)) {
        console.warn(`Language ${String(nextLocale)} is not supported.`);

        return;
      }

      try {
        const nextMessages = await loadLocaleData(nextLocale);

        saveUserLanguageCode(nextLocale);

        di.register("locale", asValue(nextLocale));

        setState({ locale: nextLocale, messages: nextMessages });
      } catch (error) {
        if (isDev) {
          console.error("Unable to switch locale!\n", error);
        }

        captureException(error);
      }
    },
    [locale],
  );

  useLayoutEffect(() => {
    di.register("locales", asValue(locales));

    void switchLocale(getUserLocale(defaultLocale));
  }, [locales, defaultLocale]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!locale || !locales?.length) {
    return null;
  }

  return (
    <IntlContext.Provider value={{ switchLocale, locales, locale }}>
      <BaseIntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale={DEFAULT_LOCALE}
        onError={handleIntlError}
        onWarn={handleIntlWarning}
      >
        {children}
      </BaseIntlProvider>
    </IntlContext.Provider>
  );
};
